var searchContainer = document.getElementById("search-overlay");
var searchTrigger = document.getElementById("search-trigger");
var searchClose = document.getElementById("search-close"); // or whatever triggers the toggle

searchTrigger.addEventListener("click", function(e) {
  e.preventDefault();
  searchContainer.classList.toggle("block");
  searchContainer.classList.toggle("hidden"); // or whatever your active class is
});

searchClose.addEventListener("click", function(e) {
  searchContainer.classList.toggle("hidden"); // or whatever your active class is
});
